@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~jquery-ui/themes/base/base.css';
@import '~jquery-ui/themes/base/theme.css';
@import '~jquery-ui/themes/base/datepicker.css';
@import "./EnquiryBundle/css/custom.css";
@import "./EnquiryBundle/css/decisiontree.css";
@import "./EnquiryBundle/css/semester.css";
@import "./EnquiryBundle/css/unsw.css";

/* @import "../EnquiryBundle/external/jquery-ui-1.13.1/jquery-ui.min.css"; */


/* @import "../EnquiryBundle/external/jquery-ui-1.13.1/jquery-ui.theme.min.css"; */