.label-width {
    width: 120px;
}

.label-width-vlong {
    width: 180px;
}

.form-width-long {
    width: 420px;
}

.form-height {
    height: 45px;
}

.box-shadow {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .25);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .25);
}

body {
    background-color: #efefef;
    margin-top: -20px;
}

.container {
    margin: 0 auto;
    max-width: 980px;
}

.navbar {
    border-radius: 0;
}

.footerlinks li {
    display: inline-block;
    text-shadow: 0;
}

.navbar-nav>li>a {
    padding-bottom: 7px;
    padding-top: 7px;
}

.footerlinks .divider {
    color: #FFE600;
    padding: 0 5px;
}

.footerlinks {
    margin-left: -40px;
}

.footerlinks a {
    color: #FFE600;
    text-shadow: none;
    font-size: 12px;
}

#backtotop {
    bottom: 0;
    right: 0;
    float: right;
}

#backtotop a {
    background: url("../images/arrow-yellow.png") no-repeat scroll right bottom rgba(0, 0, 0, 0);
    color: #FFFFFF;
    display: block;
    font-weight: bold;
    height: 17px;
    padding: 0 25px 0 0;
    font-size: 12px;
}

footer {
    color: #999999;
}

footer p {
    color: #999999;
    font-size: 12px;
}

.navbar {
    border-radius: 0;
}

.navbar {
    border: 1px solid rgba(0, 0, 0, 0);
    min-height: 35px;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0px;
}

div.navbar ul.nav li a {
    font-size: 14px;
    color: #fff;
    border-right: 1px solid #555;
}

.nav>li>a {
    border-right: 1px solid #666;
    box-shadow: none;
    font-size: 13px;
    font-weight: bold;
}

.navbar-inverse .navbar-nav>li>a {
    color: #fff;
}

.nav-container {
    padding-left: 0px;
    border: 0px;
}

.navbar-collapse {
    padding-left: 0px;
}

.nav>li>a {
    font-weight: bold;
    font-size: 13px;
}

.navbar-inverse .navbar-nav>li>a:hover {
    background-color: #FFE600;
    color: #fff;
}

.navbar-inverse .navbar-brand {
    color: #fff;
    margin-left: 15px;
}

.navbar-inverse {
    background-image: -webkit-linear-gradient(top, #625e5f 0, #231f20 100%);
    background-image: -O-linear-gradient(top, #625e5f 0, #231f20 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#625e5f), to(#231f20));
    background-image: linear-gradient(to bottom, #625e5f 0, #231f20 100%);
    /* filter: Progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0); */
    /* filter: Progid: DXImageTransform.Microsoft.gradient(enabled=false); */
    background-repeat: repeat-x
}

.main-container {
    background-color: #fff;
    box-shadow: 0 4px 4px 1px #777777;
    margin-bottom: 30px;
}

footer {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
}

.footer-container {
    border-bottom: 5px solid #FFE600;
    background-color: #000;
    color: #fff;
    padding-top: 10px;
}

.header-container {
    border-bottom: 10px solid #FFE600;
    height: 120px;
}

.logo {
    margin-top: 20px;
    margin-left: -10px;
    width: 202px;
}

.logo-xs {
    margin-top: 5px;
    margin-left: -10px;
}

.site-title {
    font-size: 32px;
    margin-right: -10px;
    margin-top: 30px;
    font-weight: bold;
    line-height: 1;
}

.site-title-xs {
    font-size: 18px;
    margin-right: -10px;
    margin-top: 20px;
    font-weight: bold;
    line-height: 1;
}

a.site-title,
a.site-title-xs {
    text-decoration: none;
    color: #000;
}

.site-title:hover,
.site-title-xs:hover {
    text-decoration: none;
    color: #000;
}

#show-errors {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

#show-errors:empty {
    display: none;
}

.popover {
    background-color: #efefef;
}

.popover.right .arrow:after {
    border-right-color: #efefef;
}

.popover-content {
    font-size: 12px;
    font-weight: none;
}

div.popover {
    width: 320px;
}

.form-control::-moz-placeholder {
    opacity: 1;
    font-style: italic;
}

.form-control:-ms-input-placeholder {
    color: #999;
    font-style: italic;
}

.form-control::-webkit-input-placeholder {
    color: #999;
    font-style: italic;
}

.prog-bar {
    margin-top: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
}

div.well.prog-bar>div {
    margin-top: 10px;
    margin-bottom: 10px;
}

.progress {
    background-color: #ccc;
}

.padding-top-medium {
    padding-top: 11px;
}

.padding-top-small {
    padding-top: 5px;
}

.btn-default-gray {
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #e0e0e0 100%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #e0e0e0 100%);
    /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0); */
    /* filter: progid: DXImageTransform.Microsoft.gradient(enabled=false); */
    background-repeat: repeat-x;
    border-color: #dbdbdb;
    text-shadow: 0 1px 0 #fff;
    border-color: #ccc;
}

.btn-default-gray:hover,
.btn-default:focus {
    background-color: #e0e0e0;
    background-position: 0 -15px;
}

.btn-default-gray:active,
.btn-default-gray.active {
    background-color: #e0e0e0;
    border-color: #dbdbdb;
}

@media (min-width: 768px) and (max-width: 991px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }
    .table-responsive>.table {
        margin-bottom: 0;
    }
    .table-responsive>.table>thead>tr>th,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tfoot>tr>td {
        white-space: nowrap;
    }
    .table-responsive>.table-bordered {
        border: 0;
    }
    .table-responsive>.table-bordered>thead>tr>th:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child {
        border-left: 0;
    }
    .table-responsive>.table-bordered>thead>tr>th:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child {
        border-right: 0;
    }
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th,
    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td {
        border-bottom: 0;
    }
}

.form-group .input-group .form-width-long {
    width: 255px;
}

.panel-default .panel-body {
    padding-top: 5px;
    padding-bottom: 5px;
}


/*
#breadcrumbs {
    font-size: 12px;
	height:2.5em;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left:-15px;
}

#breadcrumbs li {
    font-size: 12px;
	float:left;
	line-height:2.5em;
	padding-left:1em;
	color:#666666;
	<!--background-image:url(../images/arrow.gif);-->
	background-position:0 15px;
	background-repeat:no-repeat;
    list-style-type: none;
}

#breadcrumbs li.first {
    font-size: 12px;
	background-image:none;
	margin-right:5px;
}
#breadcrumbs li.last-child {
    font-size: 12px;
	background-image:none;
	margin-right:5px;
    margin-left:-12px;
}

#breadcrumbs li a {
    font-size: 12px;
	color:#666666;
	display:block;
	text-decoration:none;
	padding:0 10px 0 0;
	font-weight:bold;
	text-decoration:underline;
}*/