/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 02/12/2014, 2:06:16 PM
    Author     : z3110599
*/

#banner {
    background: url("../images/bg-banner.png") no-repeat scroll 0 0 rgba(0,0,0,0);
    height: 224px;
    position: relative;
    margin: 0px -15px 0px -15px;
}

#banner-text-1 div {
    background: none repeat scroll 0 0 #ffcc00;
    clear: both;
    color: #000000;
    float: left;
    font-family: lato,arial;
    font-size: 38px;
    font-weight: normal;
    padding: 10px 20px 5px;
}

#banner-text-2 div {
    background: none repeat scroll 0 0 #ffcc00;
    clear: both;
    color: #000000;
    float: left;
    font-family: lato,arial;
    font-size: 38px;
    font-weight: normal;
    line-height: 36px;
    padding: 0 20px 15px;
}

.banner-text-block{
    font-size: 12px;
    position: absolute;
    right: 32px;
    top: -150px;
    width: 300px;
}

ol.breadcrumb {
    background: url("../images/breadcrumb-front.png") no-repeat scroll left top transparent;
}

ol.breadcrumb {
    float: left;
    font-size: 11px;
    list-style: outside none none;
    margin: 20px 0;
    padding: 0;
}
ol.breadcrumb li.first.front {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}

ol.breadcrumb li.front a {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    line-height: 24px;
}

ol.breadcrumb li.first a {
    padding-left: 15px;
}

div.decision-tree a.btn {
    width: 125px;
}

div.decision-tree p{
    font-size: 12px;
}

div.decision-tree div.row {
    padding: 10px 0px 10px 0px;
}

div.decision-tree div.conditional-menu-hidden {
    display: none;
}

/* vertical align the decision tree menu and description */
div.vcenter {
    display: flex;
    align-items: center;
}
div.vcenter p {
    margin-bottom: 0px;
}
